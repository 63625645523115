<template>
  <div>
    <div class="pageTitle">信件補發</div>
    <div class="borderColor mb-4">
      <div>
        <div class="m-2">群組列表:</div>
        <div class="d-flex align-items-center flex-wrap m-2 mb-3">
          <date-picker
            style="width: 150px; margin-left: 5px"
            placeholder="寄送日期"
            v-model="sendTime"
            valueType="format"
            format="YYYY-MM-DD"
            :disabled-date="disabledDateAfterYesterday"
            @change="getDailyreport(null)"
          />
          <input
            type="text"
            list="group"
            class="m-2"
            v-model="selectDailyReport"
            @change="getDailyreport(true)"
          />
          <datalist id="group">
            <option
              v-for="(i, idx) in dailyReportArray"
              :key="idx"
              :value="i.group"
            >
              {{ i.factories }}
            </option>
          </datalist>
          <button class="deleteButton" @click="selectDailyReport = ''">
            清空
          </button>
        </div>
        <div class="m-2">收件人:</div>
        <div class="d-flex align-items-center flex-wrap m-2 mb-3">
          <div v-for="(i, idx) in sendmailArray" :key="idx">
            <input :value="i" class="m-2" style="width: 250px" />
            <button class="deleteButton" @click="emailSplice(idx)">移除</button>
          </div>
        </div>
        <div class="m-2">新增收件人:</div>
        <div class="d-flex align-items-center flex-wrap m-2 mb-3">
          <input v-model="newEmailsValue" class="m-2" style="width: 250px" />
          <button class="confirmButton m-5px" @click="emailPush">新增</button>
        </div>

        <div class="m-2">增加資訊:</div>
        <div class="d-flex align-items-center flex-wrap m-2 mb-3">
          <b-form-checkbox-group
            v-model="selectedPR"
            :options="selectPROptions"
            class="ml-3 mt-1"
          />
        </div>

        <div class="d-flex align-items-center flex-wrap m-2 mb-3">
          <!-- 內容: -->
          <b-spinner v-if="dataLoading" big class="m-4" />

          <div
            v-for="(i, index) in dailyData"
            :key="index"
            class="m-2 p-1"
            style="background: #ebebec; width: 500px"
          >
            <table class="w-100">
              <tr>
                <td>案場名稱</td>
                <td>{{ i.reports.factoryTwName }}</td>
              </tr>
              <tr>
                <td>案場設置容量</td>
                <td>{{ dataHandle(i.reports.capacity) }} kWp</td>
              </tr>
              <tr>
                <td>今日發電量</td>
                <td>{{ dataHandle(i.reports.acpAll) }} kWh</td>
              </tr>
              <tr>
                <td>總發電量</td>
                <td>{{ dataHandle(i.reports.AC_kWh) }} kWh</td>
              </tr>
              <tr>
                <td>今日累積日照量</td>
                <td>{{ dataHandle(i.reports.IRR) }} kWh/m²</td>
              </tr>
              <tr>
                <td>Specific Yield</td>
                <td>{{ dataHandle(i.reports.peakWatt) }} kWh/kWp</td>
              </tr>
              <tr>
                <td>今日 PR</td>
                <td>{{ dataHandle(i.reports.PR) }} %</td>
              </tr>
              <template v-if="selectedPR?.length">
                <tr>
                  <td>設計 PR 值</td>
                  <td>{{ dataHandle(i.reports.monDesignPR * 100) }} %</td>
                </tr>
                <tr>
                  <td>本月累積 PR 值</td>
                  <td>{{ dataHandle(i.reports.monPR * 100) }} %</td>
                </tr>
              </template>
            </table>
          </div>
        </div>
      </div>
      <button
        type="button"
        class="confirmButton d-flex mx-auto mb-3"
        @click="sendmail"
      >
        信件寄送
      </button>
    </div>
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'
import {
  getDailyReportListApi,
  getDailyReportApi,
  getSendMailsApi
} from '@/api'
import { DateTime } from 'luxon'
import { dbDataHandle } from '@/store/function/common'
import { disabledDateAfterYesterday } from '@/utils/datetime'

export default {
  name: 'EmailSend',
  components: {
    BSpinner
  },
  data () {
    return {
      dataLoading: false,
      newEmailsValue: '',
      selectDailyReport: '',
      dailyReportArray: [],
      dailySiteArray: [],
      sendTime: DateTime.local()
        .minus({ days: 1 })
        .setZone('Asia/Taipei')
        .toFormat('yyyy-MM-dd'),
      sendmailArray: [],
      selectedPR: [],
      selectPROptions: ['設計 PR 資訊']
    }
  },
  computed: {
    dailyData () {
      return this.dailySiteArray.map((obj) => {
        const { monPR, monDesignPR, ...reportsRest } = obj.reports
        return {
          factoryName: obj.factoryName,
          reports: Object.assign(
            reportsRest,
            this.selectedPR?.length && { monPR, monDesignPR }
          )
        }
      })
    }
  },
  methods: {
    disabledDateAfterYesterday,
    async getDailyreportList () {
      const dailyreportlist = await getDailyReportListApi()
      if (dailyreportlist?.data?.success) {
        this.dailyReportArray = dailyreportlist.data.data
        this.selectDailyReport = dailyreportlist.data.data[0].group
      }
    },
    async getDailyreport (factoryChange) {
      if (
        this.dailyReportArray.find((x) => x.group === this.selectDailyReport)
      ) {
        this.dataLoading = true
        this.dailySiteArray = []
        const dailyReport = await getDailyReportApi(
          this.selectDailyReport,
          this.sendTime
        )
        if (dailyReport?.data?.success) {
          this.dailySiteArray = dailyReport.data.data.result
          if (factoryChange) this.sendmailArray = dailyReport.data.data.mails
          this.selectedPR = []
        }
        this.dataLoading = false
      }
    },
    async sendmail () {
      if (
        !this.dailyReportArray.find((x) => x.group === this.selectDailyReport)
      ) {
        this.$swal('請選擇群組')
        return
      }
      if (!this.sendmailArray?.length) return this.$swal('請新增收件人')
      const sendmail = await getSendMailsApi(
        this.selectDailyReport,
        this.sendTime,
        this.dailyData,
        this.sendmailArray
      )
      if (sendmail?.data?.success) {
        this.$swal('寄送成功')
      }
    },
    emailPush () {
      if (
        this.newEmailsValue !== '' &&
        this.newEmailsValue.indexOf('@') !== -1
      ) {
        this.sendmailArray.push(this.newEmailsValue)
      } else {
        this.$swal('請填寫正確emails格式')
      }
    },
    emailSplice (idx) {
      this.sendmailArray.splice(idx, 1)
    },
    dataHandle (val) {
      return dbDataHandle(val)
    }
  },
  async created () {
    await this.getDailyreportList()
    this.getDailyreport(true)
  }
}
</script>

<style lang="scss" scoped>
table td {
  border: 1px solid rgb(175, 170, 170);
  padding: 5px;
  color: #333;
}
</style>
